.whatido-body{
  height: fit-content;
  width: 100vw;
  margin-top: -100px;
  background: var(--whitishPurple);
  padding-bottom: 200px;
}

#text-above-vertical-line{
  color: var(--darkgray);
  font-family: Raleway;
  text-align: center;
}

#vertical_line{
  height: 300px;
  width: 0.5px;
  background: var(--darkgray);
  margin: auto;
}

.text-container{
  padding: 20px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.JW-title{
  font-family: Arvo;
  font-size: 36px;
  width: max-content;
  border-bottom: 5px solid var(--purplishBlue);
  padding: 10px;
  font-weight: 900;
  color: var(--charcoal);
}

.JW-text{
  font-size: 20px;
  font-family: Raleway;
  color: var(--charcoal);
}

.LinkButton{
  padding: 15px 45px;
  color: var(--purplishBlue);
  font-size: 20px;
  font-family: Raleway;
  font-weight: 900;
  text-decoration: none;
  border: 1px solid var(--purplishBlue);
  position: relative;
}

.LinkButton_text{
  display: inline-block;
  position: relative;
}

.JW-patterns{
  background-size: 11px;
}

.drop-background{
  display: inline-block;
  position: absolute;
  padding: 16px 0;
  top: 0px;
  left: 0px;
  right: 4px;
  bottom: 4px;
  color: black;
  text-align: center;
  opacity: 0.1;
  background: var(--purplishBlue);
  color: var(--theWhite);
  font-family: Raleway;
  font-weight: 900;
}

.drop-background:hover{
  background: var(--theBlue);
}

/*Portfolio*/
#center-title{
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
}

.grid-of-sample-works{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}

.grid-of-sample-works li{
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.series-buttons{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px 5px;
  margin: 10px;
  width: 200px;
  height: 100px;
  color: var(--purplishBlue);
  font-size: 20px;
  font-family: Raleway;
  font-weight: 900;
  text-decoration: none;
  border: 1px solid var(--purplishBlue);
  position: relative;
  cursor: pointer;
  background: none;
}

.series-drop-background{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding: 16px 5px;
  top: 0px;
  left: 0px;
  right: 4px;
  bottom: 4px;
  color: black;
  text-align: center;
  opacity: 0.1;
  background: var(--purplishBlue);
  color: var(--theWhite);
  font-family: Raleway;
  font-weight: 900;
}

.series-drop-background:hover{
  background: var(--theBlue);
}