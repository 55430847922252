@keyframes linear_dash{
  0% { margin-left: 100%; width: 0;}
  100%   { margin-left: 0; width: 100px;}
}

.portfolio-body{
  display: flex;
  align-items: top;
  justify-content: center;
  padding-top: min(50vh,300px);
  min-height: 100vh;
  height: max-content;
  width: 100vw;
  background: var(--whitishPurple);
}

.portfolio-body li{
  margin-bottom: 16px;
}

.work-body{
  display: flex;
  align-items: top;
  justify-content: center;
  flex-direction: column;
  padding-top: min(50vh,300px);
  min-height: 100vh;
  height: max-content;
  width: 100vw;
  background: var(--whitishPurple);
}

.flex-direction-row{
  display: flex;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  flex-direction: row-reverse;
  align-items: center;
  width: 90vw;
}

.work-size-svg{
  max-width: 500px;
  min-width: 300px;
}

.work-size-svg path{
  stroke-linejoin: round;
}

.work-size-svg #react-rotate{
  transform: scale(0.1) translate(90px, 12px) rotate(50deg);
  stroke: var(--transDarkerBlue);
}

.work-size-svg #python-translate{
  transform: translate(10px, 1.5px) scale(0.05);
  fill: var(--transDarkerBlue);
}

.work-size-svg .inverse-svg{
  transform: scale(1,-1) translate(0,-7);
}

.work-size-svg #left-eye{
  transition: 0s all linear;
}

.work-size-svg #right-eye{
  transition: 0s all linear;
}

@media only screen and (max-width: 1000px){
  .flex-direction-row{
    flex-direction: column;
  }

  .text-introduction-about-myself-container{
    flex-direction: column;
  }

  .column-reverse{
    flex-direction: column-reverse;
  }

  .work-size-svg{
    margin-bottom: 50px;
  }

  .JW-work-flex-title{
    padding: 0;
  }

  .JW-work-text-column{
    padding: 0;
  }
}

.JW-work-center-title{
  font-family: Arvo;
  text-align: center;
  font-size: 36px;
  padding: 10px;
  font-weight: 900;
  color: var(--charcoal);
}

.JW-work-dot-center{
  color: var(--purplishBlue);
  font-size:70px;
  padding-left: 5px;
  
}

.JW-work-text-column{
  font-size: 18px;
  font-family: Raleway;
  color: var(--charcoal);
  padding: 0px 40px;
}

.text-portfolio-container{
  padding: 20px;
  max-width: 600px;
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
}

.text-introduction-work-container{
  padding: 20px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.text-introduction-about-myself-container{
  padding: 20px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  border-top: 1px solid var(--darkgray);
}

.text-introduction-about-myself-container div{
  margin: 20px;
}

.text-introduction-about-myself-container #withSvg{
  position: relative;
}

.text-introduction-about-myself-container img {
  position: absolute;
  max-width:210px;
  max-height: 210px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  left: 60px; 
  right: 0; 
  margin-top: 37px;
  margin-left: auto; 
  margin-right: auto;
  border-radius: 50%;
  border: 10px solid var(--whitishPurple);
}

.text-introduction-about-myself-container .WorkLinkButton{
  margin-top: 50px;
  margin-left: 0;
  margin-right: auto;
}

.text-introduction-about-myself-container h1{
  font-family: Arvo;
  display: inline-block;
  font-size: 30px;
  font-weight: 900;
  margin-bottom: 0px;
  margin-top: 50px;
  color: var(--charcoal);
}

.text-introduction-about-myself-container p{
  font-size: 18px;
  font-family: Raleway;
  color: var(--charcoal);
}

.JW-portfolio-title{
  font-family: Arvo;
  display: inline-block;
  font-size: 36px;
  border-bottom: 5px solid var(--purplishBlue);
  padding: 10px;
  font-weight: 900;
  color: var(--charcoal);
  margin-bottom: 50px;
}

.JW-work-title{
  font-family: Arvo;
  display: inline-block;
  font-size: 36px;
  padding: 10px;
  font-weight: 900;
  color: var(--charcoal);
  margin-bottom: 0;
}

.JW-work-flex-title{
  font-family: Arvo;
  display: inline-block;
  font-size: 28px;
  font-weight: 900;
  margin-bottom: 30px;
  padding: 0 40px;
  color: var(--charcoal);
}

.JW-work-dot{
  color: var(--purplishBlue);
  font-size:70px;
  padding-left: 5px;
}

.JW-work-flex-dot{
  color: var(--purplishBlue);
  font-size:50px;
  padding-left: 5px;
}

@media only screen and (max-width: 600px){
  .JW-work-title{
    font-size: 25px;
    padding: 0px;
  }

  .JW-work-flex-title{
    padding: 0;
  }

  .JW-work-text-column{
    padding: 0;
  }

  .JW-work-dot{
    font-size:50px;
  }

  .JW-work-center-title{
    text-align: left;
    font-size: 36px;
    line-height: 40px;
  }
}

.LinkButton_text{
  font-weight: 900;
}

.drop-background{
  font-weight: 900;
}

.JW-work-dash{
  width: 100px;
  height: 4px;
  background: var(--purplishBlue);
  margin-bottom: 35px;
  animation: 1s linear_dash;
}

.JW-portfolio-text{
  font-size: 18px;
  font-family: Raleway;
  color: var(--charcoal);
  padding-bottom: 50px;
}

.JW-work-text{
  font-size: 18px;
  font-family: Raleway;
  color: var(--charcoal);
  padding-bottom: 50px;
}

.JW-portfolio-text .theB{
  font-size: 24px;
  font-weight: 700;
  display: block;
}

.JW-portfolio-text a{
  text-decoration: none;
  color: var(--purplishBlue);
  font-weight: 600;
  border-bottom: 1px solid var(--purplishBlue);
}

.img-portfolio{
  max-width: 600px;
  max-height: 600px;
  margin-left: auto;
  margin-right: auto;
  width: 90vw;
  border: 0.5px solid var(--purplishBlue);
  border-radius: 10px;
}

/*Button*/
.WorkLinkButton{
  padding: 15px 45px;
  color: var(--purplishBlue);
  font-size: 20px;
  font-family: Raleway;
  font-weight: 900;
  text-decoration: none;
  border: 1px solid var(--purplishBlue);
  position: relative;
  display: block;
  width: max-content;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}

.WorkLinkButton_text{
  display: inline-block;
  position: relative;
}

.work-drop-background{
  display: inline-block;
  position: absolute;
  padding: 16px 0;
  top: 0px;
  left: 0px;
  right: 4px;
  bottom: 4px;
  color: black;
  text-align: center;
  opacity: 0.1;
  background: var(--purplishBlue);
  color: var(--theWhite);
  font-family: Raleway;
  font-weight: 900;
}

#add-transition{
  transition: 0.1s all linear;
}

.work-drop-background:hover{
  background: var(--theBlue);
}

.JW-work-patterns{
  background-size: 11px;
}

.JW-video-portfolio{
  margin-top: 20px;
  object-fit: contain;
  max-width: 600px;
  width: 90vw;
  border-radius: 20px;
  box-shadow: 0 1px 1px 0 var(--whitishPurple), 0 1px 10px 0 var(--purplishBlue);
}