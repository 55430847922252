@keyframes text-flip-up {
  from {
    opacity: 0%;
    transform: rotate3d(1, 0, 0, -90deg);
  }
  to {
    opacity: 20%;
    transform: rotate3d(0);
  }
  to {
    opacity: 100%;
    transform: rotate3d(none);
  }
}

.introduction-container{
  position: relative;
}

.introduction-body{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: var(--whitishPurple);
}

.blur-logo{
  position: relative;
  margin: 0;
  width: 100%;
  height: 100%;
  padding: 100px;
  transition: 0.1s all ease-in-out;
  z-index: 500;
}

.blur-logo svg{
  filter: blur(60px);
  -webkit-filter: blur(60px);
  transform: translate3d(0, 0, 0);
}

.introduction-text{
  position: absolute;
  display: block;
  top: 60%;
  left: 0;
  right: 0;
  margin: auto;
  font-weight: 300;
  transform: rotate(none);
  top: 44%;
  font-family: Arvo;
  font-size: min(8vw, 100px);  
  text-wrap: nowrap;
  padding: 50px;
  color: var(--charcoal);
  animation: text-flip-up 2s;
  transition: 0.5s all linear;
}

#JW-introduction{
  font-family: Bungee;
  text-wrap: nowrap;
  color: var(--purplishBlue);
}

#introduction-subtitle{
  font-family: Raleway;
  font-size: max(3.5vw,60px);
  display: block;
  line-height: 100px;
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 900px){
  #introduction-subtitle{
    font-size: 20px;
    line-height: 30px;
  }

  .introduction-text{
    padding: 10px;
    top: 20vh;
  }
}

/*Under Construction: https://codesandbox.io/s/under-construction-component-animation-7l1wl4*/
@keyframes cloud-floating{
  0% { transform: translate(0,  0px) rotate3d(1,0,0.1,50deg)}
  50%  { transform: translate(0, 15px) rotate3d(1,0,0.1,50deg)}
  100%   { transform: translate(0, -0px) rotate3d(1,0,0.1,50deg)}  
}

@keyframes slow-cloud-floating{
  0% { transform: translate(0,  0px)}
  50%  { transform: translate(0, 10px)}
  100%   { transform: translate(0, -0px)}  
}

.JW-title-wrap{
  font-family: Arvo;
  font-size: 36px;
  width: max-content;
  border-bottom: 5px solid var(--purplishBlue);
  padding: 10px;
  font-weight: 900;
  color: var(--charcoal);
}

@media only screen and (max-width: 600px){
  .JW-title-wrap{
    width: auto;
  }
}

.construction-page{
  position: relative;
  height: 250px;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  animation: cloud-floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.purple-background-construction{
  height: 220px;
  width: 220px;
  background: var(--charcoal);
  border-radius: 10px;
}

#blur-effect-code{
  color: var(--theWhite);
  font-size: 50px;
  font-family: Bungee;
  margin-top: 20px;
  opacity: 0.5;
}

.above-construction{
  position: absolute;
  color: var(--theWhite);
  text-shadow: var(--purplishBlue) 1px 0 10px;;
  font-size: 50px;
  top: 0;
  left: 10px;
  font-family: Bungee;
  animation: slow-cloud-floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}