@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

.jw-navbar{
  position: fixed;
  display: flex;
  justify-content: space-between;
  height: 72px;
  width: 100%;
  background: transparent;
  z-index: 999;
  transition: 0.3s all ease-in-out;
}

.jw-navbar[disabled]{
  background: var(--theWhite);
  height: 60px;
  box-shadow: 0 1px 1px 0 var(--whitishPurple), 0 1px 10px 0 var(--purplishBlue);
  transition: 0.3s all ease-in-out;
}

.jw-beacon{
  display: block;
  margin-top: 0;
  height: 50px;
  width: 50px;
  padding: 10px;
  padding-left: 5vw;
  font-family: 'Bungee';
  text-wrap: nowrap;
  float: left;
  animation: fade-in 3s;
  transition: 0.3s all ease-in-out;
}

.jw-beacon-svg{
  display: flex;
}

.jw-beacon[disabled]{
  height: 42px;
  width: 42px;
  transition: 0.3s all ease-in-out;
}

@media only screen and (max-width: 900px){
  .jw-beacon{
    height: 32px;
    width: 32px;
    padding-left: 2vw;
  }

  .jw-beacon[disabled]{
    height: 30px;
    width: 30px;
  }
}

.options-navbar{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.options-navbar li{
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.options-navbar a{
  text-decoration: none;
}

.nav-menu{
  display: none;
  background: none;
  border: none;
  cursor: pointer;
}

.nav-menu-active{
  display: none;
  background: none;
  border: none;
  cursor: pointer;
}

.nav-menu:hover li{
  height: 2px;
  margin-bottom: 4px;
  transition: 0.3s all ease-in-out;
}

.nav-menu-active:hover li{
  height: 2px;
  transition: 0.3s all ease-in-out;
  margin-bottom: -2px;
}

.nav-menu-active:hover li:nth-child(1){
  height: 2px;
  transition: 0.3s all ease-in-out;
  margin-bottom: -1px;
}

.nav-menu ul{
  margin-top: auto;
  margin-bottom: auto;
  list-style: none;
  padding: 20px;
}

.nav-menu-active ul{
  margin-top: auto;
  margin-bottom: auto;
  list-style: none;
  padding: 20px;
}

.nav-menu li{
  height: 1px;
  width: 20px;
  padding: 0px;
  margin-bottom: 5px;
  background: var(--purplishBlue);
  transition: 0.3s all ease-in-out;
}

.nav-menu-active li{
  height: 1px;
  width: 20px;
  padding: 0px;
  margin-bottom: 5px;
  background: var(--purplishBlue);
  transition: 0.3s all ease-in-out;
}

.nav-menu-active li{
  transform: rotate(50deg);
  margin-bottom: -1px;
}

.nav-menu-active li:nth-child(1){
  transform: rotate(90deg);
  margin-bottom: 0px;
  background: transparent;
}

.nav-menu-active li:last-child{
  transform: rotate(-50deg);
  margin-bottom: 0px;
}

.jw-options-link{
  text-decoration: none;
}

.jw-options{
  margin-right: 50px;
  color: var(--purplishBlue);
  font-size: 16px;
  font-family: Raleway;
  font-weight: 500;
  border-bottom: 2px solid transparent;
}

.jw-options:hover{
  border-bottom: 2px solid var(--purplishBlue);
  transition: 0.2s border ease-in-out;
}

.open-menu-from-navbar{
  height: 100vh;
  width: 100vw;
  margin-left: 0;
  background: var(--theWhite);
  position: fixed;
  overflow-y: scroll;
  display: block;
  z-index: 998;
  transition: 0.3s margin-left ease-in-out;
}

.open-menu-from-navbar[disabled]{
  margin-left: 100%;
  transition: 0.3s margin-left ease-in-out;
}

.open-menu-from-navbar div{
  margin-top: 100px;
  margin-left: 20px;
  margin-bottom: 0px;
  font-size: 20px;
  display: flex;
  flex-direction: row;
}

.open-menu-from-navbar p{
  margin-top: auto;
  margin-bottom: auto;
  margin-right: -5px;
  margin-left: 0px;
  width: 30px;
  height: 1.5px;
  background: var(--charcoal);
  transition: 0.3s all ease-in-out;
  transition-delay: 0.5s;
}

.open-menu-from-navbar[disabled] p{
  width: 5px;
  margin-right: 25px;
  transition: 0.1s all ease-in-out;
}

.open-menu-from-navbar a{
  padding: 20px;
}

.open-menu-from-navbar a .navbar-fontawesome{
  margin: auto auto;
  padding: 10px;
  border-radius: 10px;
  background: transparent;
}

.open-menu-from-navbar a:hover .navbar-fontawesome{
  background: var(--transBlue);
}

.open-menu-from-navbar ul{
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}

.open-menu-from-navbar a{
  color: var(--purplishBlue);
  transition: 0.3s all ease-in-out;
  transition-delay: 0.2s;
}

.open-menu-from-navbar[disabled] a{
  color: transparent;
  transition: 0.1s all ease-in-out;
}

.open-menu-from-navbar li{
  padding: 20px 50px;
  font-family: Arvo;
  font-weight: 900;
  font-size: 20px;
  border-bottom: 0.5px dotted var(--purplishBlue);
  text-shadow: 0px 0px 5px transparent;
  transition: 0.3s all ease-in-out;
}

.open-menu-from-navbar li:hover{
  background-color: var(--transBlue);
  transition: 0.1s all ease-in-out;
}

.copyright-text{
  position: fixed;
  display: flex;
  flex-direction: column;
  font-family: Arvo;
  font-size: 12px;
  color: var(--darkgray);
  z-index: 9999;
  bottom: 200px;
  left: -60px;
  transform: rotate(-90deg);
}

.copyright-dash{
  height: 1px;
  width: 20px;
  background: var(--darkgray);
  margin-left: -27px;
  margin-top: -9px;
}

@media only screen and (max-width: 900px){
  .jw-options:hover{
    border-bottom: 2px solid transparent;
    transition: 0.2s border ease-in-out;
  }
  
  .options-navbar{
    display: none;
  }  

  .nav-menu{
    display: unset;
  }

  .nav-menu-active{
    display: unset;
  }

  .copyright-text{
    position: static;
    transform: rotate(0);
    text-align: center;
    left: 0;
    bottom: 0;
    width: 100vw;
    background: var(--whitishPurple);
    padding-bottom: 20px;
  }

  .copyright-dash{
    display: none;
  }
}

@media only screen and (min-width: 901px){
  .open-menu-from-navbar{
    margin-left: 100%;
  }
  .open-menu-from-navbar[disabled]{
    margin-left: 100%;
  }
}

.navbar-fontawesome{
  padding-right: 10px;
}