:root {
  --purplishBlue: #4A55A9;
  --theBlue: #0000ff;
  --transBlue: #E0DEFD;
  --transDarkerBlue: #4a55a96f;
  --whitishPurple: #F9F7FF;
  --whitishYellow: #FFF7D6;
  --yellowOrange: #ffbb00;
  --theWhite: #FFFFFF;
  --charcoal: #414042;
  --tranCharcoal: #f9f7ffb5;
  --darkgray: #919191;
}

[aos="fade"] {
  opacity: 0;
  transition-property: opacity;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Bungee';
  src: local('Bungee'), url(./fonts/Bungee/Bungee-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(./fonts/Raleway/Raleway-VariableFont_wght.ttf) format('opentype');
}

@font-face {
  font-family: 'Arvo';
  src: local('Arvo'), url(./fonts/Arvo/Arvo-Regular.ttf) format('opentype');
}
