@keyframes cloud-floating{
  0% { transform: translate(0,  0px) rotate3d(1,0,0.1,50deg)}
  50%  { transform: translate(0, 15px) rotate3d(1,0,0.1,50deg)}
  100%   { transform: translate(0, -0px) rotate3d(1,0,0.1,50deg)}  
}

@keyframes slow-cloud-floating{
  0% { transform: translate(0,  0px)}
  50%  { transform: translate(0, 10px)}
  100%   { transform: translate(0, -0px)}  
}

.not-found-page-container{
  position: relative;
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.not-found-page{
  position: absolute;
  height: 200px;
  width: 200px;
  background: var(--purplishBlue);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 70px;
  font-family: Bungee;
  border-radius: 20px;
  transform: rotate(20deg);
  color: var(--theWhite);
  box-shadow: 0 1px 1px 0 var(--whitishPurple), 0 1px 10px 0 var(--purplishBlue);
}

#centerLink{
  display: flex;
  justify-content: center;
}