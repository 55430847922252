footer{
  height: 100px;
  width: 100vw;
  background: var(--whitishPurple);
  display: flex;
  align-items: center;
  border-top: 1px solid var(--transDarkerBlue);
}

footer ul{
  max-width: 1000px;
  width: 100%;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-evenly;
}

footer li{
  list-style-type: none;
  padding: 0;
  margin: 0;
}

footer ul a .navbar-fontawesome{
  font-size: 25px;
  color: var(--purplishBlue);
  background: transparent;
  margin: auto auto;
  padding: 10px;
  border-radius: 10px;
}

footer ul a:hover .navbar-fontawesome{
  background: var(--transBlue);
}